@keyframes page-load {
	100% {
		opacity: 1;
	}
}

@keyframes rotate {
	33% {
		transform: rotate(-30deg);
	}
	66% {
		transform: rotate(30deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes letter {
	100% {
		opacity: 1;
		letter-spacing: 0.5rem;
	}
}

@keyframes less-spacing {
	100% {
		opacity: 1;
		letter-spacing: 0.3rem;
	}
}

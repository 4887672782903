* {
	font-family: trispace, sans-serif !important;
}

body {
	margin: 0;
	padding: 0;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

body::-webkit-scrollbar {
	display: none;
}
